import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Text } from 'grommet';

import ImgixImage from '../ImgixImage';
import SmartLink from '../SmartLink';
import TextUnderlined from '../TextUnderlined';
import formatCurrency from '../../lib/formatCurrency';
import TextTitle from '../TextTitle';

const DesignerItemProductCard = ({
  collectionImageUrl,
  title,
  description,
  showDescription,
  showTitle = true,
  showPrice,
  detailsPath,
  price,
  pad,
  imageFit = 'contain',
  imageFill = false,
  sizes,
  designer,
}) => {
  return (
    <Box
      gap="small"
      pad={
        pad
          ? pad
          : {
              horizontal: 'large',
              vertical: 'small',
              bottom: 'medium',
            }
      }
      justify="center"
      align="start"
    >
      <SmartLink
        to={detailsPath}
        plain
        title={title}
        fill
        a11yTitle={title}
        style={{ display: 'block' }}
      >
        <ImgixImage
          alignSelf="center"
          src={collectionImageUrl}
          srcSetOptions={{
            q: 80,
            auto: ['format', 'compress'],
            w: sizes ? undefined : 200,
          }}
          alt={title}
          fit={imageFit}
          fill={imageFill}
          width="100%"
          sizes={sizes}
          lazy={true}
        />
      </SmartLink>

      {(showTitle || showDescription) && (
        <Box>
          <SmartLink to={detailsPath} plain>
            {showTitle && <TextTitle>{title}</TextTitle>}
          </SmartLink>
          {designer?.value && (
            <Box margin={{ top: '0.5ch' }}>
              <SmartLink to={detailsPath} plain>
                <Text size="small">by {designer.value}</Text>
              </SmartLink>
            </Box>
          )}
          {showDescription && (
            <Box>
              <SmartLink to={detailsPath} plain>
                <Text textAlign="start">{description}</Text>
              </SmartLink>
            </Box>
          )}
        </Box>
      )}
      {showPrice && (
        <Box align="center">
          <TextUnderlined size="small" weight={500} textAlign="center">
            {price && formatCurrency(price)}
          </TextUnderlined>
        </Box>
      )}
    </Box>
  );
};

DesignerItemProductCard.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  collectionImageUrl: PropTypes.string.isRequired,
  productField: PropTypes.string,
  productOptions: PropTypes.array,
  shopifyProducts: PropTypes.array,
  variants: PropTypes.array,
  productType: PropTypes.string,
  headingLevel: PropTypes.number,
  description: PropTypes.string,
  showDescription: PropTypes.bool,
  showProductType: PropTypes.bool,
  showPrice: PropTypes.bool,
  showTitle: PropTypes.string,
  price: PropTypes.number,
  cta: PropTypes.string,
  overlay: PropTypes.bool,
  sampleVariant: PropTypes.object,
  backgroundColor: PropTypes.string,
  isDark: PropTypes.bool,
  detailsPath: PropTypes.string.isRequired,
  tags: PropTypes.array,
  onAddToCart: PropTypes.func,
  pad: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageFit: PropTypes.oneOf(['fit', 'fill', '']),
  imageFill: PropTypes.bool,
  sizes: PropTypes.string,
  designer: PropTypes.object,
};

export default memo(DesignerItemProductCard);
